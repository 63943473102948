<template>
  <v-card flat tile :loading="loading">
    <v-alert v-if="lastImport.status === 'current'" type="info">
      Die Druck- und Kopierbewegungen werden nur einmal pro Tag aktualisiert.<br />
      Zuletzt geschah dies am <DateValue :value="lastImport.date" /> um
      {{ lastImport.time }}.
    </v-alert>
    <v-alert v-if="lastImport.status === 'old'" type="warning">
      Die Druck- und Kopierbewegungen sind schon länger nicht mehr aktualisiert
      worden, zuletzt am <DateValue :value="lastImport.date" /> um
      {{ lastImport.time }}.
    </v-alert>
    <v-alert v-if="lastImport.status === 'running'" type="warning">
      Die Druck- und Kopierbewegungen werden gerade aktualisiert.
    </v-alert>
    <v-toolbar flat class="mb-4">
      <v-dialog max-width="500" width="100%" v-model="transactionDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="info" class="mr-2">
            Buchen
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Neue Buchung</v-card-title>
          <v-card-text>
            <v-text-field
              class="mr-2"
              hide-details
              v-model="transactionDescription"
              label="Beschreibung"
            ></v-text-field>
            <v-text-field
              class="mr-2"
              hide-details
              v-model="transactionAmount"
              label="Betrag"
              prefix="CHF"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="createTransaction" color="success">Buchen</v-btn>
            <v-btn @click="transactionDialog = false" color="error"
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn v-if="canBalance" @click="createBalanceTransaction" color="error"
        >Saldieren</v-btn
      >
    </v-toolbar>
    <v-data-table
      class="pb-4"
      dense
      :headers="headers"
      :items="items"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.date="{ item }">
        <DateValue :value="item.date" />
      </template>
      <template v-slot:item.amount="{ item }">
        <CurrencyValue :value="item.amount" />
      </template>
      <template v-slot:item.balance="{ item }">
        <CurrencyValue :value="item.balance" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.manual" @click="deleteTransaction(item.id)" small
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { saveChildRoute } from '@/router/helper';
import CurrencyValue from '@/components/CurrencyValue';
import DateValue from 'common/components/DateValue.vue';

export default {
  name: 'PersonPrint',
  components: {
    CurrencyValue,
    DateValue,
  },
  props: {
    id: String,
    person: {},
  },
  computed: {
    canBalance() {
      return Math.abs(this.balance) > 0.25;
    },
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      balance: 0.0,
      headers: [
        { text: 'Beschreibung', value: 'description' },
        { text: 'Datum', value: 'date' },
        { text: 'Zeit', value: 'time' },
        { text: 'Betrag', value: 'amount', align: 'right' },
        { text: 'Saldo', value: 'balance', align: 'right' },
        { text: '', value: 'actions', sortable: 'false' },
      ],
      transactionDialog: false,
      transactionAmount: 0,
      transactionDescription: '',
      items: [],
      lastImport: {},
      loading: false,
    };
  },
  methods: {
    async createBalanceTransaction() {
      if (
        await this.$root.confirm({
          message: 'Soll das Druckkonto wirklich saldiert werden?',
          color: 'warning',
          icon: 'mdi-cash',
        })
      ) {
        if (this.canBalance) {
          await this.apiPost({
            resource: 'finance/printtransaction',
            data: {
              amount: -this.balance,
              description: 'Schlussbuchung',
              person: this.personId,
            },
          });
          this.getData();
        }
      }
    },
    async createTransaction() {
      await this.apiPost({
        resource: 'finance/printtransaction',
        data: {
          amount: this.transactionAmount,
          description: this.transactionDescription,
          person: this.personId,
        },
      });
      this.transactionDialog = false;
      this.getData();
    },
    async deleteTransaction(id) {
      await this.apiDelete({ resource: 'finance/printtransaction', id: id });
      this.getData();
    },
    async getData() {
      if (this.personId) {
        this.loading = true;
        const items = await this.apiList({
          resource: 'finance/printtransaction',
          query: `student=${this.personId}`,
        });
        this.balance =
          items.length == 0 ? 0.0 : items[items.length - 1].balance;
        this.items = items;
        this.lastImport = await this.apiList({
          resource: 'finance/printimportstate',
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
    person() {
      if (this.person && this.person.type && this.person.type.id != 4) {
        this.$router.push({ name: 'PersonContact' });
        return;
      }
    },
  },
  async created() {
    if (this.person && this.person.type && this.person.type.id != 4) {
      this.$router.push({ name: 'PersonContact' });
      return;
    }
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
